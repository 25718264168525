body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

header {
  padding: 20px;
  background-color: #fff;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

header input {
  padding: 10px;
  width: 300px;
  margin-right: 10px;
}

header button {
  padding: 10px 20px;
  margin-right: 5px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.items-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.item-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  width: 200px;
}

.item-card img {
  max-width: 100%;
  border-radius: 5px;
}

.selected-items {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  padding: 0px;
}

.item {
  display: flex;
  align-items: center;
  /* border: 1px solid #ddd; */
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 12px 20px;
  border-radius: 0;
  background-color: #fff;
  margin-bottom: 0px;
}

.item-image {
  flex-shrink: 0;
  /* margin-right: 20px; */
}

.item-image img {
  width: 100px; /* Adjust size as needed */
  height: auto;
  border-radius: 0px;
}

.item-details {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.item-meta {
  display: flex;
  gap: 20px;
  align-items: center;
}

.item-description,
.item-weight,
.item-price {
  margin: 0;
  white-space: nowrap;
}

.item-icon {
  margin-left: 20px;
  font-size: 24px; /* Adjust size as needed */
}
.body-container {
  background-color: #f2f5f9;
}

body {
  transition: all 0.3ms ease-in-out;
}
@media (min-width: 768px) {
  .canvas-container {
    height: 97dvh;
    flex: 1 1;
  }
  .canvas-and-panel {
    width: 650px;
    order: 1;
  }
  .home-container {
    flex-flow: row;
    justify-content: center;
    padding: 0px !important;
  }
  .control-wrapper {
    order: 2;
  }
  .tab-content {
    width: auto;
  }
  .side-panel,
  .sarees-container,
  .jewelry-container {
    flex-direction: column;
    width: unset;
  }
  .controls-container {
    overflow-y: scroll;
    min-height: 80dvh;
    max-height: 80dvh;
    width: 100%;
  }
  .saree-item {
    width: 100%;
    height: auto;
  }
  .saree-image {
    width: 100px;
    height: auto;
    padding: 10px;
  }
}
.nav-link {
  color: #333 !important;
}
.nav-link.active {
  background-color: #0d6efd !important;
  color: #fff !important;
}
