.similar-item {
    flex-basis: calc(33.333% - 10px); /* Adjust to fit the grid without breaking */
    text-align: center;
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box; /* Ensure padding is included in the width */
  }
  
  .similar-item img {
    max-width: 100%; /* Ensure image scales properly */
    height: auto;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .similar-item p {
    margin: 5px 0;
    font-size: 14px;
    color: #333;
  }
  
  .similar-item button {
    background-color: #0972ae;
    color: #333;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }