/* General Canvas Container */
/* .canvas-container {
  position: relative;
  width: 625px;
  margin: 0 auto;
  background-color: #f8f8f8;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */

/* Canvas Element */
canvas {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Canvas Button Group Positioning */
.canvas-button-group {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  z-index: 1;
}

/* Button and Options Container */
.button-with-options {
  position: relative;
  display: flex;
  align-items: center;
  background: #0005;
  border-radius: 30px;
}

/* Model and Live Buttons Styling */
.model-button,
.live-button,
.custom-file-upload {
  padding: 10px 20px;
  /* background-color: #007bff; */
  background-color: transparent;
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
}

.model-button:hover,
.live-button:hover {
  background-color: #0056b3;
}

/* Upload Controls Styling - Positioned Directly Below Buttons */
.upload-controls {
  position: absolute;
  top: 100%; /* Position right below the button */
  left: 0;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Custom File Upload Button */
.custom-file-upload {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.custom-file-upload:hover {
  background-color: #45a049;
}

/* Hide the default file input */
.custom-file-upload input[type="file"] {
  display: none;
}

/* Webcam Buttons */
.upload-controls button {
  padding: 10px 20px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.upload-controls button:hover {
  background-color: #1976d2;
}
/* Tablet View */
/* Adjusted position for tablet */
/* @media (max-width: 768px) {
  .canvas-button-group {
    right: 20px;
    top: 20px;
  }
} */
@media (max-width: 768px) {
  .canvas-container {
    width: 100%; /* Full width on tablet */
    padding: 20px; /* Adjusted padding for tablet */
  }
  /* Adjusted position for tablet */

  /* .canvas-button-group {
    right: 20px;
    top: 20px; 
  } */
  /* Adjusted top position for tablet */

  .model-button,
  .live-button {
    padding: 8px 15px; /* Adjusted padding for tablet */
    font-size: 14px; /* Adjusted font size for tablet */
  }

  .upload-controls {
    width: auto; /* Ensure it fits well on tablet */
    padding: 10px; /* Adjust padding for tablet */
    left: -48px;
  }

  .custom-file-upload,
  .upload-controls button {
    padding: 8px 15px; /* Adjusted padding for tablet */
    font-size: 14px; /* Adjusted font size for tablet */
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .canvas-container {
    width: 100%; /* Full width on mobile */
    padding: 15px; /* Adjusted padding for mobile */
  }

  .canvas-button-group {
    top: 15px; /* Adjusted top position for mobile */
    right: 10px; /* Adjusted right position for mobile */
    flex-direction: column; /* Stack buttons vertically on mobile */
  }

  .model-button,
  .live-button {
    padding: 8px 10px; /* Adjusted padding for mobile */
    font-size: 12px; /* Adjusted font size for mobile */
  }

  .upload-controls {
    width: auto; /* Ensure it fits well on mobile */
    padding: 10px; /* Adjust padding for mobile */
  }

  .custom-file-upload,
  .upload-controls button {
    padding: 8px 10px; /* Adjusted padding for mobile */
    font-size: 12px; /* Adjusted font size for mobile */
  }
}
/* Webcam Border Style */
.webcam-border {
  border: 3px solid blue; /* You can adjust the thickness and color here */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
