body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Ipad Mini */
@media screen and (min-width:768px)and (min-height:1024px)and (orientation: portrait) {
  .canvas-container {   
    width: 515px!important;
    align-items: center;
    display: flex;
    /* height: 88dvh !important; */
  }
  .canvas-container canvas{
    width: 515px!important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/* Ipad Air / Samsung Tab*/
@media screen and (min-width:800px)and (min-height:1110px)and (orientation: portrait) {
  .canvas-container {
    height: auto !important;
  }
  .canvas-container {   
    width: 600px!important;
  }
  .canvas-container canvas{
    width: 600px!important;
  }
  .controls-container{
  padding: 10px!important;  
  }
  .dropdown-content {
    top: 125px !important;
  }
  .selected-items {
    max-width: 100% !important;
    width: 96% !important;
  }
  .canvas-button-group{
    right: 25px !important;
  }
  button.btn_trash.btn.btn-primary, button.btn_capture.btn.btn-primary{
    right: 25px !important;
  }
}
/* Ipad Pro */
@media screen and (min-width:1024px)and (min-height:1366px)and (orientation: portrait) {
  .canvas-container {
    height: auto !important;
  }
  .canvas-container {   
    width: 700px!important;
  }
  .canvas-container canvas{
    width: 700px!important;
  }
}
@media screen and (min-width:1366px)and (min-height:1024px)and (orientation: landscape) {
  .canvas-container {
    height: 89dvh!important;
}
.home-container {
  margin-top: 30px !important;
 }
}

input.search-input {
  transition: all ease-in-out .3s;
}

input.search-input:focus-visible {
  box-shadow: 0px 0px 5px 1px #2626266e;
  outline: none;
}

button.btn_trash.btn.btn-primary,
button.btn_capture.btn.btn-primary,
button.btn_upload.btn.btn-primary label{
  position: absolute;
  z-index: 2;
  right: 10px;
  height: auto;
  background: #21252966;
  font-size: 25px;
  padding: 10px 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  cursor: pointer ;

}
button.btn_trash.btn.btn-primary:hover{
  background-color: red;
}

button.btn_upload.btn.btn-primary label:hover{
  background-color: rgb(0, 94, 255);
}
button.btn_upload.btn.btn-primary input{
  appearance: none;
  display: none;
}
button.btn_trash.btn.btn-primary{ top: 120px;}

/* button.btn_upload.btn.btn-primary label{
  top: 120px;
} */
button.btn_capture.btn.btn-primary{
  top: 65px;
}