/* Controls Container */
.controls-container {
  gap: 20px;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 0px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  overflow: hidden;
}

/* Sarees Container (Horizontal Layout) */
.sarees-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  /* flex-wrap: wrap; */
}

.saree-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* height: 80px; */
  cursor: pointer;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.saree-image {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: transform 0.3s ease-in-out;
}

.saree-image:hover {
  transform: scale(1.1);
}

/* .saree-item p {
  font-size: 14px;
  color: #333;
  text-align: center;
} */

/* Jewelry Container (Vertical Layout) */
.jewelry-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  overflow-x: scroll;
  width: 100%;
}

.jewelry-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 0px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.jewelry-main-image {
  width: 100px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: transform 0.3s ease-in-out;
}

.jewelry-main-image:hover {
  transform: scale(1.1);
}

.checkbox-label {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
}

.jewelry-checkbox {
  margin-left: 5px;
  transform: scale(1.2);
  cursor: pointer;
}

.jewelry-item p {
  font-size: 14px;
  color: #333;
  text-align: center;
}
/* Tablet View */
@media (max-width: 768px) {
  .controls-container {
    padding: 15px;
    gap: 15px; 
  }
  /* Adjusted gap for tablet */

  .sarees-container {
    justify-content: flex-start; /* Align items to the start */
    gap: 10px; /* Adjusted gap for tablet */
  }

  /* .saree-item {
    width: 70px;
    height: 70px;
  }

  .saree-image {
    width: 50px;
    height: 50px;
  } */

  .jewelry-container {
    gap: 15px; /* Adjusted gap for tablet */
  }

  .jewelry-main-image {
    width: 80px; /* Slightly smaller on tablet */
  }

  .jewelry-checkbox {
    transform: scale(1.1); /* Adjusted scale for tablet */
  }

  .jewelry-item p {
    font-size: 13px; /* Slightly smaller font for tablet */
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .controls-container {
    padding: 10px;
    gap: 10px; /* Adjusted gap for mobile */
  }

  .sarees-container {
    justify-content: flex-start; /* Align items to the start */
    gap: 8px; /* Adjusted gap for mobile */
  }

  .saree-item {
    width: 100%;
    /* height: 60px; */
  }

  .saree-image {
    width: 100px;
    height: auto;
  }

  .jewelry-container {
    gap: 10px; /* Adjusted gap for mobile */
  }

  .jewelry-main-image {
    width: 70px; /* Smaller size for mobile */
  }

  .jewelry-checkbox {
    transform: scale(1); /* Adjusted scale for mobile */
  }

  .jewelry-item p {
    font-size: 12px; /* Smaller font for mobile */
  }
}
