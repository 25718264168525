.image-search-container {
  position: fixed;
  top: 10%; 
  left: 50%;
  transform: translate(-50%, 0); 
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 20;
  max-width: 90%; 
  width: auto; 
  max-height: 90vh; 
  overflow-y: auto; 
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  box-sizing: border-box; 
}

.image-search-dropdown {
  display: flex;
  flex-direction: column; /* Stack options vertically */
  }

/* Style for each option in the dropdown (photo search, camera search) */
.image-search-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 5px 0;
  background-color: #f9f9f9; /* Optional: Background color for options */
}

.image-search-option:hover {
  background-color: #444;
  color: #fff;
}

.option-icon {
  margin-right: 10px;
}

.selected-image-preview {
  margin-top: 10px;
  text-align: center; /* Center the uploaded image */
  display: flex;
  justify-content: center; /* Center the image container */
  align-items: center; /* Center the image vertically within its container */
  height: auto; /* Auto height to fit the image */
}

.selected-image-preview img {
  max-width: 250px; /* Restrict the maximum width */
  max-height: 300px; /* Restrict the maximum height */
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
  margin-bottom: 20px;
}

/* Container for similar items section */
.similar-items {
  margin-top: 20px;
}

/* Grid for displaying similar items */
.items-grid {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: scroll;
  gap: 10px;
  justify-content: space-between; /* Ensure items are spaced evenly */
}

/* Individual item container in the similar items grid */
.similar-item {
  flex-basis: calc(33.333% - 10px); /* Adjust to fit the grid without breaking */
  text-align: center;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 0px;
  box-sizing: border-box; /* Ensure padding is included in the width */
  font-weight: bold;
  font-size: 13px;
}
.similar-item:hover{
  background-color: #0d6efd14;
}
.similar-item img {
  max-width: 100%; /* Ensure image scales properly */
  height: auto;
  overflow: hidden;
  margin-bottom: 0px;
  border-radius: 0px;
}

.similar-item p {
  margin: 5px 0;
  font-size: 14px;
  color: #333;
}

.similar-item button {
  background-color: #0d6efd;
  color: #fff;
  padding: 5px 20px;
  border: none;
  border-radius: 0px;
  cursor: pointer;
}

/* Hover effect for the button */
.similar-item button:hover {
  background-color: #2b7ffb;
}

/* Default button style */
.btn {
  background-color: #19b96f; 
  color: white; 
  border: none; 
  border-radius: 4px; 
  padding: 10px 20px;
  font-size: 16px; 
  cursor: pointer;
  transition: background-color 0.3s ease; 
  height: 10%;
}

/* Hover state for enabled button */
.btn:hover {
  background-color: #22579c; /* Darker blue when hovering */
}

/* Disabled state */
.btn:disabled {
  background-color: #cccccc; /* Gray background */
  color: #322f2f; /* Lighter text color */
  cursor: not-allowed; /* Not-allowed cursor for disabled state */
}

/* Active state (when the button is pressed) */
.btn:active {
  background-color: #0972ae; /* Darker blue when active */
}

/* Specific style for the "Done" button */
.btn-done {
  font-weight: bold; /* Bold font */
  text-transform: uppercase; /* Uppercase text */
}

.similar-done{
  display: flex;
  justify-content: space-between;
}

/* Tablet View */
@media (max-width: 768px) {
  .image-search-container {
    width: 90%; /* Reduce width for tablet */
    padding: 15px; /* Adjust padding for tablet */
    top: 50%; /* Adjust top position for tablet */
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .image-search-dropdown {
    flex-direction: column; /* Stack options vertically on tablet */
    align-items: center; /* Center the dropdown items */
  }

  .image-search-option {
    width: 100%; /* Full width for options on tablet */
    margin: 5px 0; /* Adjust margin for tablet */
  }

  .selected-image-preview img {
    max-width: 200px; /* Smaller image for tablet */
    max-height: 250px;
  }

  .items-grid {
    flex-basis: calc(50% - 10px); /* Two items per row on tablet */
  }

  .btn {
    padding: 8px 15px; /* Adjust padding for tablet */
    font-size: 14px; /* Adjust font size for tablet */
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .image-search-container {
    width: 95%; /* Reduce width further for mobile */
    padding: 10px; /* Adjust padding for mobile */
    top: 50%; /* Adjust top position for mobile */
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .image-search-dropdown {
    flex-direction: column; /* Stack options vertically on mobile */
    align-items: center; /* Center the dropdown items */
  }

  .image-search-option {
    width: 100%; /* Full width for options on mobile */
    margin: 5px 0; /* Adjust margin for mobile */
  }

  .selected-image-preview img {
    max-width: 150px; /* Smaller image for mobile */
    max-height: 200px;
  }

  .items-grid {
    flex-basis: 100%; /* One item per row on mobile */
  }

  .btn {
    padding: 6px 10px; /* Adjust padding for mobile */
    font-size: 12px; /* Adjust font size for mobile */
  }
}
