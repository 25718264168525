/* TryOn.css */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
 
}

.canvas-and-panel {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}

.side-panel {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  width: 100vw;
  /* position: absolute; */
  bottom: 0;
  overflow: hidden;
}

.side-panel-title {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.item-list {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; */
  display: block;
  width: 100%;
  overflow: hidden;
  /* padding: 0px 20px; */
}

/* .canvas-container {
  flex: 1;
  max-width: 600px;
  background-color: #f8f8f8;
  border: 2px solid #ccc;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 600px; 
} */

.sarees-thumbnails {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.sarees-title {
  font-size: 18px;
  margin-bottom: 5px;
  text-align: center;
}

.sarees-list {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
}

.item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
}

.item-container img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.item-container img:hover {
  border-color: #ff9800;
}

.item-container p {
  font-size: 14px;
  margin-top: 5px;  
  text-align: center;
}

.finalize-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #ff9800;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.finalize-button:hover {
  background-color: #e68a00;
}
.canvas-container{
  width: 625px;
  height: 70dvh;
  overflow: hidden;
  border-radius: 15px;
  position: relative;
  padding: 0px !important;
}
.tab-content{
  width: 625px;
}