/* Global CSS or Home component CSS */

/* Default styles */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #FFF!important
}

/* Tablet styles */
@media (max-width: 768px) {
  .home-container {
    padding: 15px;
    background-color: #f5f5f5; /* Slightly darker background for tablets */
  }
}

/* Mobile styles */
@media (max-width: 480px) {
  .home-container {
    padding: 10px;
    background-color: #eeeeee; /* Even darker background for mobiles */
  }
}
