/* Global CSS or Component CSS */

/* Default styles */
.header {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.search-bar-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 800px;
}

.search-input {
  width: 100%;
  /* padding: 10px; */
  border:1px solid #ccc;
  border-radius: 0px;
  font-size: 16px;
  margin: 0;
}

.search-icons {
  display: flex;
  gap: 10px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.search-icon, .search-icon1 {
  font-size: 18px;
  color: #666;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.search-icon1 img {
  max-width: 30%;
  max-height: 30%;
  object-fit: contain;
}

.dropdown-content {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0px;
  padding: 20px;
  position: absolute;
  top: 65px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 800px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  z-index: 22;
}

.dropdown-columns {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.column {
  flex: 1;
  padding: 10px;
}

.column h3 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #444;
  border-bottom: 2px solid #ccc;
  padding-bottom: 8px;
}

.category-item,
.popular-item {
  width: 100%;
  /* max-width: 170px; */
  padding: 8px 10px;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
  background-color: rgb(13 110 253 / 11%);
  border-radius: 0px;
  text-align: left;
  transition: background-color 0.3s ease;
}

.category-item:hover,
.popular-item:hover {
  background-color: #4173d7;
  color: #FFF;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.image-item {
  text-align: center;
}

.displayed-image {
  width: 110px;
  height: 110px;
  object-fit: cover;
  margin-bottom: 8px;
  border-radius: 6px;
  border: 1px solid #ddd;
}

.image-item span {
  display: block;
  font-size: 12px;
  color: #333;
}

.image-item span:last-child {
  font-weight: bold;
  color: #555;
}

.similar-items-drop {
  position: relative;
  /* top: 60%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 0px;
  z-index: 20;
  max-width: 750px;
  width: 100%;
  max-height: 88vh;
  overflow-y: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 0;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  margin: 0 auto;
}

.selected-items, .finalized-items {
  width: 80%;
  max-width: 800px;
  margin: 18px auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.item-image img {
  max-width: 100px;
  margin-right: 20px;
  border-radius: 4px;
}

.item-details {
  flex: 1; 
}

.item-meta {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1;
}
.item-data {
  display: flex;
  align-items: start;
  flex-flow: column;
  gap: 3px;
}

.item-description, .item-weight, .item-price {
  font-size: 14px;
  color: #333;
  white-space: nowrap;
}
.item-price span{
  font-weight: bold;
}

.item-icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tryon-button, .send-to-billing-button {
  background-color: hsl(48, 100%, 50%);
  color: #333;
  padding: 10px;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  /* font-style: italic; */
  font-weight: bold;
}

.tryon-button:hover {
  background-color: #e6b800;
}
.done-button {
  background-color: #0972ae; /* Gold background color */
  color: #111010; /* Dark text color */
  border: none;
  border-radius: 0px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px; /* Add margin above and below */
}

.done-button:hover {
  background-color: #e6b800; /* Darker gold on hover */
}

.done-button:disabled {
  background-color: #cccccc; /* Gray background when disabled */
  cursor: not-allowed;
}
.final{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  align-items: center;
  padding: 0px 10px;
}
.final h2{
  padding: 10px;
}
/* Tablet styles */
@media (max-width: 768px) {
  .header {
    padding: 15px 0;
  }

  .search-bar-container {
    max-width: 600px;
  }

  .dropdown-content {
    max-width: 600px;
  }

  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .similar-items-drop, .selected-items, .finalized-items {
    max-width: 100%;
    width: 96%;
  }
}

/* Mobile styles */
@media (max-width: 480px) {
  .header {
    padding: 10px 0;
  }

  .search-bar-container {
    width: 100%;
    max-width: 400px;
  }

  .search-icons {
    right: 10px;
  }

  .dropdown-content {
    max-width: 100%;
    padding: 15px;
  }

  .image-grid {
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .displayed-image {
    width: 60px;
    height: 60px;
  }

  .similar-items-drop, .selected-items, .finalized-items {
    max-width: 100%;
  }

  .item {
    flex-direction: column;
    align-items: flex-start;
  }

  .item-image img {
    max-width: 80px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .item-meta {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .tryon-button {
    width: 100%;
    text-align: center;
    padding: 10px;
  }
}
